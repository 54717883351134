import PropTypes from "prop-types"
import React from "react"

import { RichTextElement } from "@kentico/gatsby-kontent-components"
import BackgroundImage from 'gatsby-background-image'

const CTA = ({ anchor, background, linkedItems, sitemap, body, lang }) => (
    <BackgroundImage 
        id={anchor}
        className="cta" 
        Tag="section" 
        fluid={[`linear-gradient(rgba(2, 2, 2, 0.1), rgba(0, 0, 0, 0.5))`, background.fluid]}>
        
        <div className="container">
            <div className="text-center">
                <RichTextElement 
                    value={body} 
                    linkedItems={linkedItems}
                    resolveLinkedItem={linkedItem => {
                        if (linkedItem.__typename === "kontent_item_content_page") {
                            const url = sitemap.find(n => 
                                n.elements.content_page && n.elements.content_page.value && n.elements.content_page.value.length &&
                                n.elements.content_page.value[0].id === linkedItem.id)?.url || "/"
                            return <a href={url} className="cta-btn">{lang === "el" ? "Άνοιγμα" : "Go"}</a>
                        }
                    }}
                />
            </div>
        </div>
    </BackgroundImage>
)

CTA.propTypes = {
    anchor: PropTypes.string,
    background: PropTypes.object.isRequired,
    sitemap: PropTypes.array.isRequired,
    body: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired
}

export default CTA