import PropTypes from "prop-types"
import React from "react"

import parse from "html-react-parser"
import innerText from "react-innertext"

import { RichTextElement } from "@kentico/gatsby-kontent-components"

const Text = ({ anchor, sectionTitleHtml, textPart1, textPart2 }) => (
    <section id={anchor} className="text-block">
        <div className="container">
            <section className="section-title">
                <RichTextElement value={sectionTitleHtml} />
            </section>
            <div className="row content">
                {textPart2 && innerText(parse(textPart2)) ? 
                    <>
                        <div className="col-lg-6">
                            <RichTextElement value={textPart1} />
                        </div>
                        <div className="col-lg-6">
                        <RichTextElement value={textPart2} />
                        </div>
                    </> :
                    (
                        <div className="col">
                            <RichTextElement value={textPart1} />
                        </div>
                    )}
            </div>
        </div>
    </section>
)

Text.propTypes = {
    anchor: PropTypes.string.isRequired,
    sectionTitleHtml: PropTypes.string,
    textPart1: PropTypes.string.isRequired,
    textPart2: PropTypes.string
}

export default Text