import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import { Container, Row, Col } from "react-bootstrap"
import { FaLink } from "react-icons/fa"

import { RichTextElement } from "@kentico/gatsby-kontent-components"

const CategoryList = ({ anchor, sectionTitle, items, oddColumn }) => (
    <section className="portfolio" id={anchor}>
        <Container>
            {sectionTitle && (
                <div className="section-title">
                    <RichTextElement value={sectionTitle} />
                </div>
            )}
            <Row className="portfolio-container spaced">
                {items.map(item => (
                    <Col key={item.id} lg={oddColumn ? 4 : 3} md={6} className="portfolio-item">
                        <Link to={item.url} title={item.name}>
                            <Img fluid={item.thumbnail.fluid} className="img-fluid" alt={item.name} />
                        </Link>
                        <Link to={item.url} className="portfolio-info" title={item.name}>
                            <h4>{item.name}</h4>
                            <span className="details-link">
                                <FaLink />
                            </span>
                        </Link>
                    </Col>
                ))}
            </Row>
        </Container>
    </section>
)

CategoryList.propTypes = {
    anchor: PropTypes.string,
    sectionTitle: PropTypes.string,
    items: PropTypes.array.isRequired,
    oddColumn: PropTypes.bool.isRequired,
}

export default CategoryList