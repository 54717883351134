import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Hero from "../components/Home/hero"
import SEO from "../components/seo"
import Header from "../components/Home/header"
import Text from "../components/Home/text"
import CTA from "../components/Home/cta"
import CategoryList from "../components/Home/category-list"

import Share from "../components/share"

import { InView } from 'react-intersection-observer'

const hrefLangs = {
  el: "/en",
  en: "/el"
}

const Home = ({ data }) => {
  const lang = data.home.system.language
  const ctaText = lang === "el" ? "Ας ξεκινήσουμε" : "Let's begin";

  const [activeAnchor, setActiveAnchor] = useState("hero")

  return (
    <Layout>
      <SEO
        lang={lang}
        title={data.home.elements.metaTitle.value}
        description={data.home.elements.metaDescription.value}
        ogTitle={data.home.elements.ogTitle.value}
        ogDescription={data.home.elements.ogDescription.value}
        ogImageUrl={
          data.home.elements.ogImage.value &&
          data.home.elements.ogImage.value.length &&
          data.home.elements.ogImage.value[0].url
        }
        hrefLangs={hrefLangs}
      />
      <Header
        siteTitle={data.home.elements.metaTitle.value}
        navItems={data.nav.elements.subitems.value}
        lang={data.home.system.language}
        activeAnchor={activeAnchor}
      />
      <Share
        url={`${data.site.siteMetadata.siteUrl}/${lang}/`}
        title={data.home.elements.metaTitle.value}
      />
      <InView onChange={val => { if (val) setActiveAnchor("hero") }}>
        <Hero
          ctaText={ctaText}
          heroImage={data.home.elements.heroImage.value[0]}
          heroMessageHtml={data.home.elements.heroMessage.value}
        />
      </InView>
      <main id="main">
        {data.home.elements.modules && data.home.elements.modules.value && data.home.elements.modules.value.map(m => (
          <InView key={m.id} onChange={val => {
            if (val && m.elements.basic_module__anchor && m.elements.basic_module__anchor.value) {
              setActiveAnchor(m.elements.basic_module__anchor.value)
            }
          }}>
            {
              m.system.type === `text_module` && (
                <Text
                  anchor={m.elements.basic_module__anchor.value}
                  sectionTitleHtml={m.elements.basic_module__module_section_title.value}
                  textPart1={m.elements.text_part_1.value}
                  textPart2={m.elements.text_part_2.value}
                />
              )
            }
            {
              m.system.type === `cta_module` && (
                <CTA
                  anchor={m.elements.anchor.value}
                  background={m.elements.background.value[0]}
                  linkedItems={m.elements.body.modular_content}
                  body={m.elements.body.value}
                  lang={lang}
                  sitemap={data.sitemap.nodes}
                />
              )
            }
            {
              m.system.type === `page_list_module` && (
                <CategoryList
                  anchor={m.elements.basic_module__anchor && m.elements.basic_module__anchor.value}
                  sectionTitle={m.elements.basic_module__module_section_title && m.elements.basic_module__module_section_title.value}
                  items={m.elements.categories.value.map(i => ({
                    id: i.id,
                    name: i.elements.name.value,
                    url: data.sitemap.nodes
                      .find(n =>
                        n.elements.content_page && n.elements.content_page.value && n.elements.content_page.value.length &&
                        n.elements.content_page.value[0].id === i.id)?.url || "",
                    thumbnail: i.elements.thumbnail_image.value[0]
                  }))}
                  oddColumn={m.elements.card_listing__layout.value && m.elements.card_listing__layout.value.length && m.elements.card_listing__layout.value[0].codename === 'odd_column'}
                />
              )
            }
          </InView>
        ))}
      </main>
    </Layout>
  )
}

export default Home

export const query = graphql`
  query homeQuery($lang: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    nav: kontentItemNavigationItem(system: {codename: {eq: "root"}, language: {eq: $lang}}) {
      fields {
        lang
      }
      elements {
        subitems {
          value {
            ... on kontent_item_navigation_item {
              elements {
                title {
                  value
                }
                anchor {
                  value
                }
              }
            }
          }
        }
      }
    }

    sitemap: allKontentItemNavigationItem(filter: {fields: {lang: {eq: $lang}}}) {
      nodes {
        fields {
          lang
        }
        url
        elements {
          content_page {
            value {
              id
            }
          }
        }
      }
    }

    home: kontentItemHome(fields: {lang: {eq: $lang}}) {
        fields {
            lang
          }
          elements {
            metaTitle: metadata__meta_title {
              value
            }
            metaDescription: metadata__meta_description {
              value
            }
            ogTitle: metadata__og_title {
              value
            }
            ogDescription: metadata__og_description {
              value
            }
            ogImage: metadata__og_image {
              value {
                url
              }
            }
            heroImage: hero_image {
              value {
                fluid(maxWidth: 1920) {
                  ...KontentAssetFluid_withWebp
                }
              }
            }
            heroMessage: hero_message {
              value
            }
            modules {
              value {
                ... on kontent_item_text_module {
                  elements {
                    basic_module__anchor {
                      value
                    }
                    basic_module__module_section_title {
                      value
                    }
                    text_part_1 {
                      value
                    }
                    text_part_2 {
                      value
                    }
                  }
                  system {
                    type
                  }
                  id
                }
                ... on kontent_item_cta_module {
                  elements {
                    anchor {
                      value
                    }
                    background {
                      value {
                        fluid(maxWidth: 1920) {
                          ...KontentAssetFluid_withWebp
                        }
                      }
                    }
                    body {
                      value
                      modular_content {
                        __typename
                        system {
                          codename
                        }
                        ... on kontent_item_content_page {
                          id
                        }
                      }
                    }
                  }
                  system {
                    type
                  }
                  id
                }
                ... on kontent_item_page_list_module {
                  elements {
                    basic_module__anchor {
                      value
                    }
                    basic_module__module_section_title {
                      value
                    }
                    card_listing__layout {
                      value {
                        codename
                      }
                    }
                    categories {
                      value {
                        ... on kontent_item_content_page {
                          id
                          system {
                            codename
                          }
                          elements {
                            thumbnail_image {
                              value {
                                fluid(maxWidth: 400) {
                                  ...KontentAssetFluid_withWebp
                                }
                              }
                            }
                            name {
                              value
                            }
                          }
                        }
                      }
                    }
                  }
                  system {
                    type
                  }
                  id
                }
              }
            }
          }
          system {
            language
          }
      }
    }
`