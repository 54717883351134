import PropTypes from "prop-types"
import React from "react"

import BackgroundImage from 'gatsby-background-image'
import AnchorLink from "react-anchor-link-smooth-scroll"

const Hero = ({ ctaText, heroImage, heroMessageHtml }) => (
  <BackgroundImage
    id="hero"
    Tag="section"
    className="hero"
    fluid={heroImage.fluid}
    style={{backgroundPosition: 'top center'}}
  >
    <div className="overlay" />
    <div className="hero-container">
      <div dangerouslySetInnerHTML={{ __html: heroMessageHtml }} />
      <AnchorLink className="btn-get-started" href="#about">{ctaText}</AnchorLink>
    </div>
  </BackgroundImage>
)

Hero.propTypes = {
  ctaText: PropTypes.string.isRequired,
  heroImage: PropTypes.object.isRequired,
  heroMessageHtml: PropTypes.string.isRequired
}


export default Hero